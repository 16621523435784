import React from "react";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link, StaticQuery, graphql } from "gatsby";

// TODO: put this somewhere!
const blogPath = function (post) {
  return post.fields.slug.replace(
    /^\/thebeat\/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})-/,
    "/$<year>/$<month>/$<day>/"
  );
};

const PostComponent = (data) => {
  const featuredPost =
    data.allMarkdownRemark.edges.length > 0
      ? data.allMarkdownRemark.edges[0].featuredPost
      : null;
  return (
    <header
      style={{ maxWidth: "1440px" }}
      className="mx-auto sm:flex items-stretch"
    >
      {featuredPost.frontmatter.featuredimage && (
        <Link to={blogPath(featuredPost)} className="sm:w-1/2">
          <PreviewCompatibleImage
            imageInfo={{
              image: featuredPost.frontmatter.featuredimage,
              alt: featuredPost.frontmatter.featuredimagealt,
              className: "w-full h-full",
              imageStyle: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
            }}
          />
        </Link>
      )}

      <div
        className={`w-full ${
          featuredPost.frontmatter.featuredimage
            ? "sm:w-1/2 mx-auto"
            : "container"
        } p-6 md:p-8 lg:p-12 xl:p-16 flex-none space-y-2 md:space-y-4 flex flex-col`}
      >
        <span className="text-navy-p1 font-medium text-sm md:text-lg">
          {featuredPost.frontmatter.date}
        </span>

        <Link to={blogPath(featuredPost)}>
          <h3 className="text-2xl md:text-3xl lg:text-4xl font-heading text-accent hover:text-accent-p1">
            {featuredPost.frontmatter.title}
          </h3>
        </Link>

        <p className="text-navy text-md md:text-lg lg:text-xl">
          {featuredPost.frontmatter.description}
        </p>

        <Link
          to={blogPath(featuredPost)}
          className="text-accent hover:text-accent-p1 font-semibold text-sm md:text-md"
        >
          Read More →
        </Link>
      </div>
    </header>
  );
};

export default function FeaturedPost() {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { featuredpost: { eq: true } } }
            limit: 1
          ) {
            edges {
              featuredPost: node {
                id
                frontmatter {
                  title
                  description
                  date(formatString: "M.D.YY")
                  author
                  featuredimagealt
                  featuredimage {
                    childImageSharp {
                      fluid(
                        maxWidth: 700
                        quality: 75
                        traceSVG: {
                          background: "transparent"
                          color: "#DBE2E8"
                        }
                      ) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                }
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
      render={PostComponent}
    />
  );
}
