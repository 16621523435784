import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import Img from "gatsby-image";
import BlogRoll from "../components/BlogRoll";
import FeaturedPost from "../components/FeaturedPost";
import SubscribeBox from "../components/SubscribeForm";
import FeatureBox from "../components/FeatureBox";

import logoSolidWhite from "../img/signature-solid-white.svg";

const Pagination = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/thebeat" : `/thebeat/page/${currentPage - 1}`;
  const nextPage = `/thebeat/page/${currentPage + 1}`;

  return (
    <div className="container pt-4 md:pt-8 sm:pb-0">
      <div className="columns">
        <div className="w-4/12 sm:w-3/12">
          {!isFirst && (
            <Link to={prevPage} rel="prev" className="hover:text-accent">
              <span className="text-accent">←</span>&nbsp;Previous
            </Link>
          )}
        </div>

        <h4 className="font-medium text-center w-4/12 text-navy-p1">
          Page {currentPage}
        </h4>

        <div className="text-right w-4/12 sm:w-3/12">
          {!isLast && (
            <Link to={nextPage} rel="next" className="hover:text-accent">
              Next&nbsp;<span className="text-accent">→</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ({ data, pageContext }) => {
  const isFirst = pageContext.currentPage === 1;

  return (
    <Layout
      headerClassName="bg-navy-p1 sm:curve-br"
      navbarLogo={logoSolidWhite}
      headerPhoto={
        <Img
          imgStyle={{
            objectFit: "contain",
            objectPosition: "100% 100%",
          }}
          fluid={data.headerPhoto.childImageSharp.fluid}
        />
      }
      className="accent-fuchsia bg-navy-p3"
      headerTitle="The Beat"
    >
      <Helmet titleTemplate="%s">
        <title>The Beat - ScriptDrop</title>
        <meta property="og:title" content="The Beat" />
      </Helmet>

      {isFirst && <FeaturedPost />}

      <div className="bg-navy-p3">
        {!isFirst && <Pagination {...pageContext} />}

        <section className="container md:pb-6">
          <div className="columns">
            <div className="w-full md:w-10/12">
              {
                // Whitepaper download feature box
                // TODO: Put into CMS
                isFirst && (
                  <div className="sm:mt-4 md:mt-8 lg:mt-12">
                    <FeatureBox
                      titlePrepend="Free Download"
                      title="Payor Coverage of Prescription Delivery Can Cut Costs, Help Millions of Americans"
                      body="A white paper on transportation insecurity and solutions to improve patients' access to medication."
                      linkText="Download PDF"
                      href="/downloads/ScriptDrop-WhitePaper-NEMT.pdf"
                    />
                  </div>
                )
              }

              <BlogRoll posts={data.allMarkdownRemark.edges} />
            </div>
          </div>

          <Pagination {...pageContext} />
        </section>
      </div>

      <section className="py-6 md:py-8 lg:py-16">
        <div className="container">
          <div className="columns items-center">
            <div className="w-full md:w-6/12 lg:w-5/12">
              <SubscribeBox title="Newsletter" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const listQuery = graphql`
  query PageListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          templateKey: { eq: "blog-post" }
          featuredpost: { ne: true }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "M.D.YY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
        }
      }
    }

    headerPhoto: file(relativePath: { eq: "media/masked-couple.png" }) {
      childImageSharp {
        fluid(
          maxWidth: 256
          quality: 80
          traceSVG: { background: "transparent", color: "#003052" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
