import React from "react";
import { Link } from "gatsby";

export default ({ title, titlePrepend, body, linkText, href }) => (
  <Link
    key={"feature-box-" + title}
    className=" md:mb-8 block rounded-lg bg-white md:rounded-xl border border-solid border-royal-p1 hover:border-royal overflow-hidden"
    to={href}
  >
    <article className={`sm:flex justify-between`}>
      <div className="p-3 md:p-6 lg:pr-12 flex flex-col justify-between space-y-2 sm:space-y-3 md:space-y-4">
        <span className="text-royal uppercase font-medium text-sm md:text-lg">
          {titlePrepend}
        </span>
        <h3 className="text-xl md:text-2xl lg:text-3xl flex-grow font-heading text-navy flex-grow">
          {title}
        </h3>

        <p>{body}</p>

        <span className="text-royal font-medium text-sm md:text-lg">
          {linkText} →
        </span>
      </div>
    </article>
  </Link>
);
