import React from "react";
import { Link } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

// TODO: put this somewhere!
const blogPath = function (post) {
  return post.fields.slug.replace(
    /^\/thebeat\/(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})-/,
    "/$<year>/$<month>/$<day>/"
  );
};

export default ({ posts }) => (
  <div className="sm:mt-4 md:mt-8 lg:mt-12 space-y-4 md:space-y-8 lg:space-y-12">
    {posts &&
      posts.map(({ node: post }) => (
        <Link
          className="block bg-white rounded-lg md:rounded-xl border border-solid border-navy-p2 hover:border-accent-p1 overflow-hidden"
          to={blogPath(post)}
          key={post.id}
        >
          <article
            className={`sm:flex justify-between ${
              post.frontmatter.featuredpost ? "is-featured" : ""
            }`}
          >
            {post.frontmatter.featuredimage ? (
              <div
                className={`${
                  post.frontmatter.featuredpost
                    ? "block w-full h-32"
                    : "hidden sm:block"
                } sm:w-4/12 sm:h-auto flex-none order-last`}
              >
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: post.frontmatter.featuredimagealt,
                    className: "w-full h-full",
                    imageStyle: {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              </div>
            ) : null}

            <div className="p-3 md:p-6 lg:pr-12 flex flex-col justify-between space-y-2 sm:space-y-3 md:space-y-4">
              <span className="text-accent font-medium text-sm md:text-lg">
                {post.frontmatter.date}
              </span>

              <h3 className="text-xl md:text-2xl lg:text-3xl flex-grow font-heading text-navy-p1 flex-grow">
                {post.frontmatter.title}
              </h3>
              <span className="text-accent-p1 font-medium text-sm md:text-lg">
                Read More →
              </span>
            </div>
          </article>
        </Link>
      ))}
  </div>
);
